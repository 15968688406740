import { useDispatch } from 'react-redux';

import { notify } from '~/actions/notify';

/**
 * Dispatches the error message through the notify reducer
 * and adds behavior config to success messages (and some other stuff).
 * @param {string} message - Message string to display in toast container.
 */
export default function useNotify() {
    const dispatch = useDispatch();

    const toastSuccess = (message, autoClose = true) => {
        dispatch(notify({ success: message, autoClose: autoClose }));
    };

    const toastError = (message, autoClose = true) => {
        dispatch(notify({ error: message, autoClose: autoClose }));
    };

    return {
        toastSuccess,
        toastError
    };
}
