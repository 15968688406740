import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/properties/:propertyID([0-9]+)',
        component: Loader(() => import('~/containers/properties/View'))
    },
    {
        path: '/properties/:propertyID([0-9]+)/units/:unitID([0-9]+)',
        component: Loader(() => import('~/containers/properties/View'))
    },
    {
        path: '/properties/:id([0-9]+)/units/add',
        component: Loader(() => import('~/containers/properties/units/Add'))
    },
    {
        path: '/properties/add',
        component: Loader(() => import('~/containers/properties/Add'))
    },
    {
        path: '/properties',
        component: Loader(() => import('~/containers/properties/List'))
    },
    {
        path: '/properties/appliances/:id([0-9]+)',
        component: Loader(() => import('~/containers/properties/appliances/View'))
    },
    {
        path: '/properties/units',
        component: Loader(() => import('~/containers/properties/units/List'))
    },
    {
        path: '/associations/:id([0-9]+)',
        component: Loader(() => import('~/containers/contacts/associations/View'))
    },
    {
        path: '/associations',
        component: Loader(() => import('~/containers/contacts/associations/List'))
    },
    {
        path: '/associations/add',
        component: Loader(() => import('~/containers/contacts/associations/Add'))
    }
];

export default routes;
