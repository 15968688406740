import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/imports/vendors',
        component: Loader(() => import('~/containers/imports/Vendors'))
    },
    {
        path: '/imports/leases',
        component: Loader(() => import('~/containers/imports/Leases'))
    },
    {
        path: '/imports/tenants',
        component: Loader(() => import('~/containers/imports/Tenants'))
    },
    {
        path: '/imports/owners',
        component: Loader(() => import('~/containers/imports/Owners'))
    },
    {
        path: '/imports/portfolios',
        component: Loader(() => import('~/containers/imports/Portfolios'))
    },
    {
        path: '/imports/properties',
        component: Loader(() => import('~/containers/imports/Properties'))
    },
    {
        path: '/imports/units',
        component: Loader(() => import('~/containers/imports/Units'))
    },
	{
        path: '/imports/accounts',
        component: Loader(() => import('~/containers/imports/Accounts'))
    },
	{
        path: '/imports/recurring-charges',
        component: Loader(() => import('~/containers/imports/RecurringCharges'))
    },
	{
        path: '/imports/security-deposits',
        component: Loader(() => import('~/containers/imports/SecurityDeposits'))
    },
	{
        path: '/imports/prepayments',
        component: Loader(() => import('~/containers/imports/Prepayments'))
    },
	{
        path: '/imports/credits',
        component: Loader(() => import('~/containers/imports/Credits'))
    },
	{
        path: '/imports/portfolio-balances',
        component: Loader(() => import('~/containers/imports/PortfolioBalances'))
    },
	{
        path: '/imports/unpaid-charges',
        component: Loader(() => import('~/containers/imports/UnpaidCharges'))
    },
	{
        path: '/imports/owner-tax-adjustments',
        component: Loader(() => import('~/containers/imports/OwnerTaxAdjustments'))
    },
	{
        path: '/imports/vendor-tax-adjustments',
        component: Loader(() => import('~/containers/imports/VendorTaxAdjustments'))
    },
	{
        path: '/imports/unpaid-bills',
        component: Loader(() => import('~/containers/imports/UnpaidBills'))
    }
];

export default routes;
