import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/contacts/owners/:id([0-9]+)',
        component: Loader(() => import('~/containers/contacts/owners/View'))
    },
    {
        path: '/contacts/owners',
        component: Loader(() => import('~/containers/contacts/owners/List'))
    },
    {
        path: '/contacts/tenants/:id([0-9]+)',
        component: Loader(() => import('~/containers/contacts/tenants/View'))
    },
    {
        path: '/contacts/tenants',
        component: Loader(() => import('~/containers/contacts/tenants/List'))
    },
    {
        path: '/contacts/messages',
        component: Loader(() => import('~/containers/contacts/messages/TextMessagesList'))
    },
    {
        path: '/contacts/messages/:id([0-9]+)',
        component: Loader(() => import('~/containers/contacts/messages/View'))
    },
    {
        path: '/contacts/tenants/messages/:id([0-9]+)',
        component: Loader(() => import('~/components/contact/messages/View'))
    },
    {
        path: '/messages/texts/phone/:id([0-9]+)',
        component: Loader(() => import('~/containers/contacts/messages/View'))
    },
    {
        path: '/contacts/messages/mass-text-messages',
        component: Loader(() => import('~/containers/contacts/messages/mass-text/MassTextMessagesList'))
    },
    {
        path: '/messages/mass-text-messages/:id([0-9]+)',
        component: Loader(() => import('~/containers/contacts/messages/mass-text/View'))
    },
    // Mass messages
    {
        path: '/contacts/messages/mass-emails',
        component: Loader(() => import('~/containers/contacts/messages/bulk-message/MassEmailsList'))
    },
    {
        path: '/contacts/messages/bulk-message/:id([0-9]+)',
        component: Loader(() => import('~/containers/contacts/messages/bulk-message/View'))
    },
    {
        path: '/contacts/messages/mass-letters',
        component: Loader(() => import('~/containers/contacts/messages/mass-letters/MassLettersList'))
    },
    {
        path: '/contacts/messages/mass-letters/:id([0-9]+)',
        component: Loader(() => import('~/containers/contacts/messages/mass-letters/View'))
    }
];

export default routes;
